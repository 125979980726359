export const theme = {
  primaryColor: '#1E2A45',
  secondaryColor: '#2A3F5F',
  accentColor: '#FFD700',
  backgroundColor: '#0F1624',
  textColor: '#FFFFFF',
  lightTextColor: '#E0E0E0',
  borderRadius: '8px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
};
