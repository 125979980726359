import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyB-odqzs7eE950fIqM50Mp8l2kxoiI0JTs',
  authDomain: 'brissbellaadminportal.firebaseapp.com',
  projectId: 'brissbellaadminportal',
  storageBucket: 'brissbellaadminportal.appspot.com',
  messagingSenderId: '50058186484',
  appId: '1:50058186484:web:499abc2e5b3ea08d3dd8a1',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { db, auth, provider };
