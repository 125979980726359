import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useProfile } from '../contexts/ProfileContext';

const loadingTexts = [
  'Brewing the perfect management potion...',
  'Styling our virtual scissors...',
  'Warming up the massage tables...',
  'Aligning the chakras of our database...',
  'Perfecting the art of digital pampering...',
  'Untangling the complexities of beauty...',
  'Polishing our virtual mirrors...',
  'Preparing a soothing ambiance...',
  'Mixing the colors of efficiency...',
  'Scheduling serenity...',
];

const SplashScreen = () => {
  const [loadingText, setLoadingText] = useState(loadingTexts[0]);
  const { profile, loading } = useProfile();

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText(
        loadingTexts[Math.floor(Math.random() * loadingTexts.length)],
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <div className="splash-screen">
      <div className="splash-content">
        <img
          src={profile.logo || '/favicon.png'}
          alt="Brissbella Logo"
          className="splash-logo"
        />
        <h1 className="splash-title">Brissbella Management Portal</h1>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        <p className="loading-text">{loadingText}</p>
      </div>
    </div>
  );
};

export default SplashScreen;
