import React, { useState, useEffect, lazy, Suspense } from 'react';
import { ConfigProvider, Layout, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from './firebase/firebaseConfig';
import { theme } from './theme';
import './index.css';
import SplashScreen from './components/SplashScreen';
import { ProfileProvider } from './contexts/ProfileContext';
import { App as AntdApp } from 'antd';

const { Header, Content } = Layout;

// Lazy load components
const Sidebar = lazy(() => import('./components/Sidebar'));
const AppRoutes = lazy(() => import('./routes'));
const HeaderComponent = lazy(() => import('./components/Header'));
const MobileMenu = lazy(() => import('./components/MobileMenu'));

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
      if (!user && location.pathname !== '/login') {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  if (loading) {
    return <Spin fullscreen />;
  }

  return (
    <AntdApp>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.primaryColor,
            borderRadius: theme.borderRadius,
          },
        }}
      >
        <Layout className="app-layout">
          <Suspense fallback={<Spin fullscreen />}>
            <ProfileProvider>
              <Header className="app-header">
                <HeaderComponent
                  user={user}
                  setUser={setUser}
                  toggleMobileMenu={toggleMobileMenu}
                />
              </Header>
              <Layout className="main-layout">
                {user && (
                  <>
                    <Sidebar className={mobileMenuVisible ? 'visible' : ''} />
                    <MobileMenu
                      visible={mobileMenuVisible}
                      onClose={() => setMobileMenuVisible(false)}
                    />
                  </>
                )}
                <Content className="content-area">
                  <AppRoutes user={user} setUser={setUser} />
                </Content>
              </Layout>
            </ProfileProvider>
          </Suspense>
        </Layout>
      </ConfigProvider>
    </AntdApp>
  );
}

export default App;
